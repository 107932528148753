<template>
	<b-modal
		centered
		:title="`Tracking de ${info.type == 1 ? 'desparasitaciones' : 'suplementos'} del corral ${
			info.alias || info.code
		}`"
		size="lg"
		v-model="isActive"
		@hidden="handleHidden"
		hide-footer
	>
		<b-overlay :show="isLoading">
			<b-table-simple sticky-header="40vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th v-if="info.type == 2">Suplemento</b-th>
						<b-th v-if="info.type == 2">Tipo</b-th>
						<b-th v-if="info.type == 2">Creado por</b-th>
						<b-th v-if="info.type == 2">Días</b-th>

						<b-th v-if="info.type == 1">Creado por</b-th>
						<b-th v-if="info.type == 1">Fecha</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, index) in rows" :key="index">
						<b-td v-if="info.type == 2">{{ row.name_vitamin }}</b-td>
						<b-td v-if="info.type == 2">{{ row.type }}</b-td>

						<b-td v-if="info.type == 2">
							<p class="mb-0">{{ row.name }}</p>
							<small>{{ row.created_at | myGlobalDayShort }}</small>
						</b-td>
						<b-td v-if="info.type == 2">
							<p>{{ row.days && row.days.join(',') }}</p>
							<p v-if="row.type == 'ocasional'">---</p>
						</b-td>

						<b-td v-if="info.type == 1">{{ row.name }}</b-td>
						<b-td v-if="info.type == 1">{{ row.created_at | myGlobalDayShort }}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import corralService from "@/services/encaste/corral.service"

export default {
	name: "ClinicalTracking",
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		rows: [],
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.vitamin = null
			this.vitaminType = null
			this.rows = []
			this.$emit("closing")
		},
		async getDeworn() {
			this.isPreloading()
			const { data } = await corralService.getHcDewornCorrales({ corral_id: this.info.id })
			this.rows = data
			this.isPreloading(false)
		},
		async getVitamins() {
			this.isPreloading()
			const { data } = await corralService.getHcVitaminsCorrales({ corral_id: this.info.id })
			const diasSemana = {
				1: 'Lu',
				2: 'Ma',
				3: 'Mi',
				4: 'Ju',
				5: 'Vi',
				6: 'Sa',
				7: 'Do'
				};
			data.forEach(element => {
				if(element.days){
					element.days = element.days.map(index => diasSemana[index])
				}
			});
			this.rows = data
			this.isPreloading(false)
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val

				if (this.info.type == 1) {
					this.getDeworn()
				} else {
					this.getVitamins()
				}
			}
		},
	},
}
</script>
