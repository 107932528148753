<template>
	<b-modal
		centered
		no-close-on-backdrop
		:title="`Aplicar suplementos al corral ${info.alias || info.code}`"
		v-model="isActive"
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<ValidationObserver ref="form">
				<b-form-group label="Suplemento">
					<ValidationProvider rules="required" v-slot="{ errors }">
						<v-select
							label="text"
							:class="{ 'border-danger rounded': errors[0] }"
							:options="vitaminOpts"
							:reduce="(opt) => opt.value"
							v-model="vitamin"
						/>
					</ValidationProvider>
				</b-form-group>
				<b-form-group label="Tipo de suplemento">
					<ValidationProvider rules="required" v-slot="{ errors }">
						<b-form-radio-group
							:options="[
								{ value: 1, text: 'Frecuente' },
								{ value: 2, text: 'Ocasional' },
							]"
							v-model="vitaminType"
						/>
						<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
					</ValidationProvider>
				</b-form-group>
				<b-form-group label="Días" v-if="vitaminType == 1">
					<!-- <b-form-checkbox-group
							id="checkbox-group-2"
							v-model="vitaminDays"
							name="flavour-2"
						>
							<b-form-checkbox :value="1">Lunes</b-form-checkbox>
							<b-form-checkbox :value="2">Martes</b-form-checkbox>
							<b-form-checkbox :value="3">Miercoles</b-form-checkbox>
							<b-form-checkbox :value="4">Jueves</b-form-checkbox>
							<b-form-checkbox :value="5">Viernes</b-form-checkbox>
							<b-form-checkbox :value="6">Sábadoj</b-form-checkbox>
							<b-form-checkbox :value="7">Domingo</b-form-checkbox>
						</b-form-checkbox-group> -->
						
					<v-select
						v-model="vitaminDays"
						label="text"
						style="width: 100%"
						multiple
						appendToBody
						:clearable="false"
						:options="days"
						:reduce="(opt) => opt.value"
						:calculatePosition="positionDropdown"
					/>
				</b-form-group>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button @click="applyVitamin" variant="primary">Aplicar</b-button>
		</template>
	</b-modal>
</template>

<script>
import preparacionSettingService from "@/services/preparacion/settings.service"
import corralService from "@/services/encaste/corral.service"

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		vitamin: null,
		vitaminOpts: [],
		vitaminDays: [],
		vitaminType: null,
		days: [
			{text: "Lunes",value:1},
			{text: "Martes",value:2},
			{text: "Miercoles",value:3},
			{text: "Jueves",value:4},
			{text: "Viernes",value:5},
			{text: "Sabado",value:6},
			{text: "Domingo",value:7}
		],
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.vitamin = null
			this.vitaminType = null
			this.vitaminDays = []
		},
		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},
		async getOpts() {
			this.isLoading = true
			const { data } = await preparacionSettingService.getVitamins({ status: 1 })
			this.vitaminOpts = data.map((opt) => ({
				value: opt.id,
				text: opt.name,
			}))
			this.isLoading = false
		},
		async applyDeworn() {
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se realizara una desparacitacion a todos los pollos del corral ${
					this.info.alias || this.info.code
				}`,
			})
			if (!isConfirmed) return
			this.isLoading = true
			await corralService.insertMassiveDeworn({ corral_id: this.info.id })

			this.isLoading = false
			this.$emit("refresh")
			this.handleHidden()
			this.showToast(
				"success",
				"top-right",
				"Corrales",
				"CheckIcon",
				"Registro de desparacitacion insertado correctamente"
			)
		},
		async applyVitamin() {
			let isDaysNull = this.vitaminDays.__ob__.value.length
			if(!isDaysNull && this.vitaminType == 1){
				this.showToast(
					"warning",
					"top-right",
					"No hay días seleccionados",
					"InfoIcon",
					"Debe marcar al menos un día de la semana"
				)
				return
			}
			if (!(await this.$refs.form.validate())) return
			const vitamin = this.vitaminOpts.find((opt) => opt.value == this.vitamin)
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se aplicara el suplemento ${vitamin.text} a todos los pollos del corral ${
					this.info.alias || this.info.code
				}`,
			})
			if (!isConfirmed) return
			this.isLoading = true
			await corralService.insertMassiveVitamin({
				corral_id: this.info.id,
				vitamin_id: this.vitamin,
				type_id: this.vitaminType,
				days: !isDaysNull ? null: this.vitaminDays,
			})
			this.isLoading = false
			this.$emit("refresh")
			this.handleHidden()
			this.showToast(
				"success",
				"top-right",
				"Corrales",
				"CheckIcon",
				"Registro de suplementos insertado correctamente"
			)
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getOpts()
			}
		},
	},
}
</script>
