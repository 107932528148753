<template>
	<div>
		<CorralesListTabs :start_date="start_date" :end_date="end_date" />
	</div>
</template>

<script>
import CorralesList from "./components/CorralesList.vue";
import CorralesListTabs from "./components/CorralesListTabs.vue";

export default {
	name: "CorralesMain",
	components: {
		CorralesList,
		CorralesListTabs,
	},
	data() {
		return {
			season: null,
			start_date: null,
			end_date: null,
		};
	},
	mounted() {
		// this.season = this.seasons[0].id;
		// this.setSeason(this.season);
	},
	methods: {
		// setSeason(e) {
		// 	const { start, end } = this.seasons.find((season) => season.id == e);
		// 	this.start_date = start;
		// 	this.end_date = end;
		// },
	},
};
</script>

<style lang="scss">
.container-select-year {
	padding: 2rem;
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 600px) {
		display: flex !important;
		flex-direction: row !important;
		justify-content: space-between !important;
	}
	.select-year {
		width: 100%;
		margin-bottom: 1rem;
		@media screen and (min-width: 600px) {
			width: 160px;
			margin-bottom: 0;
		}
	}
}

.container-select-year {
	margin: 1rem 0;
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 600px) {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	.select-year {
		width: 100%;
		// margin-bottom: 1rem;
		@media screen and (min-width: 600px) {
			width: 40% !important;
			margin-top: 0;
		}
		@media screen and (min-width: 900px) {
			width: 160px;
			margin-bottom: 0;
		}
	}
	.create-corral {
		@media screen and (min-width: 600px) {
			width: 40% !important;
			margin-top: 0;
		}
		@media screen and (min-width: 1000px) {
			width: 15% !important;
		}
	}
}
</style>
