<template>
	<div>
		<b-card class="d-flex">
			<b-row>
				<b-col>
					<ul class="nav nav-tabs align-items-center gap-x-2 mb-0" role="tabslits">
						<b-nav-item
							v-for="(tab, index) in tabArray"
							link-classes="px-1 justify-content-between"
							class="x-tab-width"
							href="#"
							:key="`${index}-index`"
							:active="tabIndex == index"
							@click="tabIndex = index"
						>
							<span>{{ tab.name }}</span>
							<b-badge pill variant="danger" class="ml-1">
								{{ tab.counter }}
							</b-badge>
						</b-nav-item>
					</ul>
				</b-col>
				<b-col class="d-flex justify-content-end">
					<CreateCorral class="create-corral" />
				</b-col>
			</b-row>
		</b-card>
		<!-- <b-card class="d-flex">
			<b-nav class="m-0" style="gap: 1rem">
				<b-nav-item
					v-for="(tab, index) in tabArray"
					link-classes="px-1 justify-content-between"
					class="x-tab-width"
					href="#"
					:key="`${index}-index`"
					:active="tabIndex == index"
					@click="tabIndex = index"
				>
					<span>{{ tab.name }}</span>
					<b-badge pill variant="danger" class="ml-1">
						{{ tab.counter }}
					</b-badge>
				</b-nav-item>
			</b-nav>
			<CreateCorral class="create-corral" />
		</b-card> -->

		<b-tabs
			content-class="mt-2"
			nav-wrapper-class="card pt-1 pr-1 pl-1 d-none"
			nav-class="align-items-center"
			v-model="tabIndex"
			lazy
		>
			<b-tab :key="index" v-for="(listCorral, index) in tabArray">
				<CorralesList
					:start_date="start_date"
					:end_date="end_date"
					:status_id="listCorral.status_id"
					@refresh-counters="getCountersCorrales"
				/>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import CorralesList from "./CorralesList.vue";
import CreateCorral from "@/views/amg/encaste/corrales/modals/CreateCorral.vue";
import corralService from "@/services/encaste/corral.service";

export default {
	name: "CorralesListTabs",
	components: {
		CorralesList,
		CreateCorral,
	},
	props: {
		start_date: {
			type: String,
		},
		end_date: {
			type: String,
		},
	},
	data() {
		return {
			tabArray: [
				{
					name: "ACTIVO",
					counter: 0,
					status_id: 1,
				},
				{
					name: "FINALIZADO",
					counter: 0,
					status_id: 2,
				},
				{
					name: "ANULADO",
					counter: 0,
					status_id: 3,
				},
			],
			tabIndex: 0,
		};
	},

	created() {
		this.$root.$on("reload-corrales-counter", async () => {
			await this.getCountersCorrales();
		});
	},
	destroyed() {
		this.$root.$off("reload-corrales-counter");
	},
	async mounted() {
		await this.getCountersCorrales();
	},
	methods: {
		async getCountersCorrales() {
			const {
				counters: [data],
			} = await corralService.getCounters({
				start_date: this.start_date,
				end_date: this.end_date,
			});
			this.tabArray[0].counter = data.activo;
			this.tabArray[1].counter = data.finalizado;
			this.tabArray[2].counter = data.anulado;
		},
	},
	watch: {
		async start_date() {
			await this.getCountersCorrales();
		},
	},
};
</script>

<style lang="scss">
.container-uls-encaste-corrales {
	display: flex;
	padding: 1rem;
	ul {
		// background: red;;
		width: 100%;
		li {
			width: 100%;
			@media screen and (min-width: 600px) {
				width: auto;
			}
		}
	}
}

.create-corral {
	white-space: nowrap;
	min-width: 12rem;

	@media screen and (min-width: 600px) {
		width: 40% !important;
		margin-top: 0;
	}
	@media screen and (min-width: 1000px) {
		width: 15% !important;
	}
}
</style>
