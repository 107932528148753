<template>
	<div class="w-100">
		<button :disabled="disabledEncaste" @click="openModal" class="btn btn-primary w-100" type="button">
			{{ corral ? "Agregar pollos" : "Agregar" }}
		</button>

		<b-modal
			v-model="modalCreareCorral.show"
			:size="corral ? 'xmd' : 'xmd'"
			:title="modalCreareCorral.title"
			hide-footer
			@hidden="resetForm"
		>
			<b-overlay :show="isLoading">
				<validation-observer ref="form">
					<div class="container-view-update-corrales">
						<b-row>
							<b-col cols="12">
								<h4>
									<b-badge>
										Pollos: {{ chicksMale.length + chicksFemale.length + chicksUnasigned.length }}
									</b-badge>
								</h4>
							</b-col>
						</b-row>
						<b-row>
							<b-col v-if="!corral" cols="12" xl="3" class="mb-1">
								<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
									<div class="d-flex align-items-center">
										<b-form-group
											label="Fecha de inicio"
											label-for=""
											valid-feedback="Thank you!"
											:invalid-feedback="errors[0]"
											:state="errors[0] ? false : null"
											class="mb-0"
										>
											<flat-pickr
												v-model="objectCorral.date"
												class="form-control bg-white"
												placeholder="Seleccionar fechas"
												:class="errors[0] ? 'is-invalid' : ''"
											/>
										</b-form-group>
									</div>
								</ValidationProvider>
							</b-col>

							<b-col cols="12" xl="4" class="mb-1">
								<div class="d-flex align-items-center w-100">
									<!-- <b-form-group label="Cintillo" class="w-100 mb-0" v-if="false">
										<v-select
											ref="searchCaCorrales"
											class="w-100"
											@input="addCintilloToCorral($event)"
											:value="chickSelect"
											:options="chicksArray"
											:clearable="false"
											label="label"
											:reduce="(option) => option"
											transition=""
											:placeholder="`Buscar por cintillo en ala`"
											:selectable="(option) => option.in_corral == 0"
											@search="
												(search, loading) =>
													getChicks({
														search,
														loading,
													})
											"
										>
											<template slot="no-options"> Sin cintillo alas encontradas </template>

											<template slot="option" slot-scope="option">
												<div class="d-center">
													{{ option.label }}
													{{ option.code_corral && ` / ${option.code_corral}` }}
												</div>
											</template>
											<template slot="selected-option" slot-scope="option">
												<div class="selected d-center">
													{{ option.label }}
												</div>
											</template>
										</v-select>
									</b-form-group> -->
									<!--  -->
									<b-form-group
										label="Cintillo"
										:invalid-feedback="otherCorralMsg"
										:state="!!!otherCorralMsg"
										class="w-100 mb-0"
									>
										<b-input-group class="mb-1">
											<b-form-input
												v-model="cInput"
												placeholder="Cintillo..."
												@keyup.enter="cSearch"
											/>
											<b-input-group-append>
												<b-button size="sm" variant="outline-primary" @click="cSearch">
													Agregar
												</b-button>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</div>
							</b-col>

							<b-col v-if="!corral" cols="12" xl="4" class="mb-1">
								<div class="d-flex align-items-center w-100">
									<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
										<b-form-group
											label="Alias"
											class="w-100 mb-0"
											:invalid-feedback="errors[0]"
											:state="errors[0] ? false : null"
										>
											<b-form-input
												:state="errors[0] ? false : null"
												v-model="objectCorral.alias"
												placeholder="Ingrese un alias"
											></b-form-input>
										</b-form-group>
									</ValidationProvider>
								</div>
							</b-col>
						</b-row>

						<div class="mb-2" v-if="false">
							<p>Corral</p>
							<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
								<b-form-input
									v-model="cintilloInputNone"
									class="d-none"
									:class="errors[0] ? 'is-invalid' : ''"
								></b-form-input>
								<div class="chick-grid" :class="errors[0] ? 'icon-invalid' : ''">
									<FeatherIcon
										:class="errors[0] ? 'd-flex icon-invalid-chicks' : 'd-none'"
										class=""
										icon="InfoIcon"
										color="red"
										size="12"
									/>
									<template v-if="chicks.length != 0">
										<div class="chick-item" v-for="(item, key) in chicks" :key="key">
											<p class="mb-0">{{ item }}</p>
											<div class="close" @click="removeCintilloFromArray(item)">
												<FeatherIcon icon="XIcon" class="icon" />
											</div>
										</div>
									</template>
									<div v-if="chicks.length == 0">
										<p class="mb-0 small" style="color: #cecece">Seleccione un cintillo en ala</p>
									</div>
								</div>
								<span class="invalid-feedback" v-if="errors[0]">{{ errors[0] }}</span>
							</ValidationProvider>
						</div>

						<div>
							<b-form-input
								v-model="cintilloInputNone"
								class="d-none"
							/>
							<b-row style="max-height: 20rem; overflow-y: auto">
								<b-col sm="12" md="4">
									<div class="mb-1">
										<p>Machos</p>
										<div class="chick-grid">
 											<draggable
												:list="chicksMale"
												group="same"
												@change="dragChange($event, 1)"
												v-bind="dragOpts"
											>
												<div
													class="chick-item bg-primary"
													v-for="(chick, idx) in chicksMale"
													:key="chick.id"
												>
													<p class="mb-0">{{ chick.label }}</p>
													<div class="close" @click="removeCintilloFromArray(idx, 1)">
														<FeatherIcon icon="XIcon" class="icon" />
													</div>
												</div>
												<p v-if="chicksMale.length == 0" class="mb-0 sin-chicks">
													Sin registros
												</p>
											</draggable>
										</div>
									</div>
								</b-col>
								<b-col sm="12" md="4">
									<div class="mb-1">
										<p>Hembras</p>
										<div class="chick-grid">
											<draggable
												:list="chicksFemale"
												group="same"
												@change="dragChange($event, 2)"
												v-bind="dragOpts"
											>
												<div
													class="chick-item bg-primary"
													v-for="(chick, idx) in chicksFemale"
													:key="chick.id"
												>
													<p class="mb-0">{{ chick.label }}</p>
													<div class="close" @click="removeCintilloFromArray(idx, 2)">
														<FeatherIcon icon="XIcon" class="icon" />
													</div>
												</div>
												<p v-if="chicksFemale.length == 0" class="mb-0 sin-chicks">
													Sin registros
												</p>
											</draggable>
										</div>
									</div>
								</b-col>
								<b-col sm="12" md="4">
									<div class="mb-1">
										<p>Sin asignar</p>
										<div class="chick-grid">
											<draggable
												:list="chicksUnasigned"
												group="same"
												@change="dragChange($event, 3)"
												v-bind="dragOpts"
											>
												<div
													class="chick-item bg-primary"
													v-for="(chick, idx) in chicksUnasigned"
													:key="chick.id"
												>
													<p class="mb-0">{{ chick.label }}</p>
													<div class="close" @click="removeCintilloFromArray(idx, 3)">
														<FeatherIcon icon="XIcon" class="icon" />
													</div>
												</div>
												<p v-if="chicksUnasigned.length == 0" class="mb-0 sin-chicks">
													Sin registros
												</p>
											</draggable>
										</div>
									</div>
								</b-col>
							</b-row>
						</div>
						<b-row v-if="!corral" class="my-1">
							<b-col cols="12" md="12">
								<b-form-group label="Comentario" label-for="comment" class="mb-0">
									<b-form-textarea
										v-model="objectCorral.comment"
										placeholder="Agregue un comentario(opcional)"
										rows="2"
										max-rows="3"
									></b-form-textarea>
								</b-form-group>
							</b-col>
							<b-col cols="12" md="6"></b-col>
						</b-row>
					</div>
				</validation-observer>

				<div class="w-100 d-flex justify-content-end">
					<button @click="resetForm" class="btn btn-secondary mr-1">Limpiar campos</button>

					<button @click="saveCorral" class="btn btn-primary">
						{{ this.corral ? "Agregar pollos" : "Guardar" }}
					</button>
				</div>
			</b-overlay>
		</b-modal>
		<b-modal title="SELECCIONAR UN CINTILLO" v-model="showModalRepeated" centered hide-footer>
			<b-row class="mb-1">
				<b-col
					class="d-flex justify-content-center align-items-center flex-column"
					cols="6"
					v-for="chick in chicksRepeated"
					:key="chick.id"
				>
					<div
						class="chick-item"
						:class="chick.corral_alias ? 'bg-secondary' : 'bg-primary'"
						@click="!chick.corral_alias && selectCintillo(chick)"
					>
						<p class="mb-0">{{ chick.label }}</p>
					</div>
					<p v-b-tooltip.hover title="Corral" class="mb-0 font-small-2 text-danger font-weight-bold">
						{{ chick.corral_alias }}
					</p>
				</b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import CorralService from "@/services/encaste/corral.service"
import chicksService from "@/services/encaste/chicks.service.js"
import moment from "moment"
import draggable from "vuedraggable"

export default {
	name: "CreateCorral",
	components: { draggable },
	props: {
		corral: {
			type: Object,
			default: () => {},
		},
		year: {
			type: Number,
		},
	},
	data() {
		return {
			corrales: [],
			modalCreareCorral: {
				show: false,
				title: "Agregar corral",
				size: "md",
			},
			objectCorral: {
				date: null,
				alias: null,
				comment: null,
			},
			chicks: [],
			chicksMale: [],
			chicksFemale: [],
			chicksUnasigned: [],
			cintillo: null,
			cintilloInputNone: null,
			chicksArray: [],
			chickSelect: null,
			dragOpts: {
				animation: 0,
				disabled: false,
				ghostClass: "ghost",
			},
			isLoading: false,
			//
			showModalRepeated: false,
			chicksRepeated: [],
			otherCorralMsg: null,
			cInput: "",
		}
	},
	mounted() {
		if (this.corral) {
			this.modalCreareCorral.title = `Agregar pollos al corral ${this.corral.code}`
			this.objectCorral.date = this.corral.start_date
		} else {
			this.modalCreareCorral.title = "Agregar corral"
			this.objectCorral.date = new Date()
		}
	},
	methods: {
		openModal() {
			this.modalCreareCorral.show = true
		},
		async getChicks({ search, loading }) {
			loading(true)
			if (search.length > 1) {
				const { message, chicks } = await CorralService.getChicks({
					search,
				})
				this.chicksArray = chicks.map((it) => {
					return {
						label: it.correlative,
						value: it.id,
						id: it.id,
						in_corral: it.in_corral,
						code_corral: it.code_corral,
						gender: it.gender,
					}
				})
			}
			loading(false)
		},
		async saveCorral() {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				)
				return
			}

			let allChicks = [...this.chicksMale, ...this.chicksFemale, ...this.chicksUnasigned]
			allChicks = allChicks.map((chick) => chick.label)

			if (this.corral) {
				this.isPreloading()

				const { message } = await CorralService.update({
					corral_id: this.corral.id,
					start_date: moment(this.objectCorral.date).format("YYYY-MM-DD"),
					add_new: true,
					chicks: allChicks,
					cintillo_alas: allChicks,
				})

				this.isPreloading(false)

				// const { message } = await CorralService.store({
				// 	start_date: this.objectCorral.date,
				// 	cintillo_alas: this.chicks,
				// });
				this.$emit("refresh")
				this.resetForm()
				this.modalCreareCorral.show = false

				this.showToast("success", "top-right", "Corrales", "AlertCircleIcon", message)
			} else {
				// Crear corrar
				this.isPreloading()
				const { message } = await CorralService.store({
					start_date: moment(this.objectCorral.date).format("YYYY-MM-DD"),
					cintillo_alas: allChicks,
					alias: this.objectCorral.alias,
					comment: this.objectCorral.comment,
				})
				this.isPreloading(false)
				this.showToast("success", "top-right", "Corrales", "AlertCircleIcon", message)
				this.resetForm()
				this.modalCreareCorral.show = false
				// this.$emit("refresh");
				this.$root.$emit("reloader-corrales-list")
				this.$root.$emit("reload-corrales-counter")
				// bus.$emit("reload-lotes-list");
			}
		},
		addCintilloToCorral(cintillo) {
			let duplicated
			switch (cintillo.gender) {
				case "MACHO":
					duplicated = this.chicksMale.find((chick) => chick.id == cintillo.id)
					if (duplicated == undefined) {
						this.chicksMale.push({
							label: cintillo.label,
							id: cintillo.id,
						})
					} else {
						this.showToast("danger", "top-right", "Error", "AlertIcon", `El cintillo ya fue agregado`)
					}
					break
				case "HEMBRA":
					duplicated = this.chicksFemale.find((chick) => chick.id == cintillo.id)
					if (duplicated == undefined) {
						this.chicksFemale.push({
							label: cintillo.label,
							id: cintillo.id,
						})
					} else {
						this.showToast("danger", "top-right", "Error", "AlertIcon", `El cintillo ya fue agregado`)
					}
					break
				case "SIN ASIGNAR":
					duplicated = this.chicksUnasigned.find((chick) => chick.id == cintillo.id)
					if (duplicated == undefined) {
						this.chicksUnasigned.push({
							label: cintillo.label,
							id: cintillo.id,
						})
					} else {
						this.showToast("danger", "top-right", "Error", "AlertIcon", `El cintillo ya fue agregado`)
					}
					break
			}
			this.$nextTick(() => {
				this.cintillo = null
				this.chicksArray = []
				this.updateSearchCintillos()
			})
		},
		removeCintilloFromArray(idx, type) {
			switch (type) {
				case 1:
					this.chicksMale.splice(idx, 1)
					break
				case 2:
					this.chicksFemale.splice(idx, 1)
					break
				case 3:
					this.chicksUnasigned.splice(idx, 1)
					break
			}
			this.updateSearchCintillos()
		},
		resetForm() {
			this.chicks = []
			this.chicksMale = []
			this.chicksFemale = []
			this.chicksUnasigned = []
			this.cintillo = null
			this.objectCorral.date = new Date()
			this.objectCorral.alias = null
			this.objectCorral.comment = null
			this.cintilloInputNone = null
			if (this.$refs["form"]) this.$refs["form"].reset()
		},
		updateSearchCintillos() {
			let allChicks = [...this.chicksMale, ...this.chicksFemale, ...this.chicksUnasigned]
			this.cintilloInputNone = allChicks.length
			if (this.cintilloInputNone == 0) {
				this.cintilloInputNone = null
			}
		},
		async dragChange(ev, type) {
			if (!ev.added) return
			this.isLoading = true
			switch (type) {
				case 1:
					await chicksService.updateChickGenderAndStatus({ chick_id: ev.added.element.id, gender: "MACHO" })
					break
				case 2:
					await chicksService.updateChickGenderAndStatus({ chick_id: ev.added.element.id, gender: "HEMBRA" })
					break
				case 3:
					await chicksService.updateChickGenderAndStatus({
						chick_id: ev.added.element.id,
						gender: "SIN ASIGNAR",
					})
					break
			}
			this.isLoading = false
			this.showToast("success", "top-right", "Pollos", "AlertCircleIcon", "Pollo Actualizado")
		},
		//
		async cSearch() {
			this.isLoading = true
			this.otherCorralMsg = null
			const { message, chicks } = await CorralService.getChicks({
				search: this.cInput,
			})
			// verificar si existe pero esta en otro corral
			let match = chicks.find((chick) => chick.correlative == this.cInput)
			if (match != undefined && match.corral_id != null) {
				this.showToast(
					"danger",
					"top-right",
					"Corral",
					"AlertIcon",
					`El cintillo se encuentra en el corral ${match.corral_alias}`
				)
				//
				this.otherCorralMsg = `El cintillo se encuentra en el corral ${match.corral_alias}`
				// setTimeout(() => {
				// 	this.otherCorralMsg = null;
				// }, 3000);
				this.isLoading = false
				return
			}
			//
			let available = chicks
			available = available.map((it) => ({
				label: it.correlative,
				value: it.id,
				id: it.id,
				in_corral: it.in_corral,
				code_corral: it.code_corral,
				gender: it.gender,
				corral_alias: it.corral_alias,
			}))
			// 21-50
			if (available.length == 1) {
				if (available[0].corral_alias) {
					this.cInput = null
					this.showToast(
						"danger",
						"top-right",
						"Corrales",
						"AlertIcon",
						`El cintillo ${available[0].label} se encuentra en el corral ${available[0].corral_alias}`
					)
					this.otherCorralMsg = `El cintillo ${available[0].label} se encuentra en el corral ${available[0].corral_alias}`
					// setTimeout(() => {
					// 	this.otherCorralMsg = null;
					// }, 20000);

					// return;
				} else {
					this.addCintilloToCorral(available[0])
				}
			} else if (available.length == 0) {
				this.showToast("danger", "top-right", "Corrales", "AlertIcon", "Cintillo no encontrado.")
			} else {
				this.chicksRepeated = available
				this.showModalRepeated = true
			}
			this.cInput = ""
			this.isLoading = false
		},
		selectCintillo(chick) {
			this.addCintilloToCorral(chick)
			this.showModalRepeated = false
		},
	},
	watch: {
		"objectCorral.date"() {
			if (this.corral) this.objectCorral.date = this.corral.start_date
		},
	},
}
</script>

<style lang="scss" scoped>
.chick-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	background: #fafafa;
	padding: 2rem;
	min-height: 8rem;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	position: relative;
	.icon-invalid-chicks {
		position: absolute;
		top: 1rem;
		right: 1rem;
	}
}

.chick-item {
	// background: #7367f0;
	width: auto;
	position: relative;
	padding: 0.3rem 0.6rem;
	margin-bottom: 0.2rem;
	border-radius: 4px;
	cursor: pointer;
	text-align: center;
	p {
		color: #fff;
		font-weight: bolder;
	}
	.close {
		z-index: 100;
		position: absolute;
		top: -10px;
		right: -10px;
		background: rgb(255, 51, 1);
		height: 20px;
		width: 20px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.5s all ease-in-out;
		cursor: pointer;
		.icon {
			color: #fff;
		}
	}
}
.icon-invalid {
	border: 1px solid #ea5455;
	border-radius: 4px;
	// background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea5455' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e);
	background-repeat: no-repeat;
	background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
}
.container-cantidad-pollos {
	position: absolute;
	top: 0;
	right: 0;
	width: 20%;
	padding: 1rem;
	display: flex;
	justify-content: flex-end;
	p {
		padding: 0.2rem 0.5rem;
		background: #7367f0;
		border-radius: 4px;
		width: auto !important;
		color: #fff;
		font-weight: bold;
	}
}

.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}

.sin-chicks {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
}
</style>
