<template>
	<b-sidebar
		style="z-index: 99999"
		size="xmd"
		width="90%"
		centered
		hide-footer
		title="Hijos"
		v-model="isActive"
		@hidden="handleHidden"
		body-class="p-2"
		header-class="border-top-2"
	>
		<b-overlay class="px-2" :show="isLoading">
			<b-row v-if="rows.length > 0">
				<b-col sm="4" lg="3">
					<b-card footer-class="d-block text-center h4 p-1" footer="Padrillo">
						<SpecimenPlate
							:specimen="{
								plate: cParents.padrillo.plate,
								alias: cParents.padrillo.alias,
								id: cParents.padrillo.id,
								thumb: cParents.padrillo.thumb,
								image: cParents.padrillo.image,
							}"
						/>
					</b-card>
				</b-col>
				<b-col sm="4" lg="3">
					<b-card footer-class="d-block text-center h4 p-1" footer="Madrilla">
						<SpecimenPlate
							:specimen="{
								plate: cParents.madrilla.plate,
								alias: cParents.madrilla.alias,
								id: cParents.madrilla.id,
								thumb: cParents.madrilla.thumb,
								image: cParents.madrilla.image,
							}"
						/>
					</b-card>
				</b-col>

				<b-col sm="12" lg="6" xl="6" class="d-flex justify-content-end mb-2">
					<!-- ASD -->
					<TableGenderBrothersBySpecimen
						class="container-table-genders-children"
						v-if="countersGender"
						:countersGender="countersGender"
					/>
				</b-col>
			</b-row>
			<div class="bg-white pb-1" style="border-radius: 8px">
				<div class="card bg-transparent align-item-center p-1 flex-lg-row justify-content-md-between">
					<ul class="nav nav-tabs align-items-center gap-x-2 mb-0 w-100" role="tabslits">
						<b-nav-item
							link-classes="px-1 justify-content-between w-100"
							:active="tab == 1"
							:disabled="specimens.length == 0"
							@click="tab = 1"
						>
							<span>EJEMPLARES</span>
							<b-badge pill variant="danger" class="ml-1" v-if="specimens.length > 0">
								{{ specimens.length }}
							</b-badge>
						</b-nav-item>
						<b-nav-item
							link-classes="px-1 justify-content-between w-100"
							:active="tab == 2"
							:disabled="countChicks == 0"
							@click="tab = 2"
						>
							<span>POLLOS</span>
							<b-badge pill variant="danger" class="ml-1" v-if="countChicks > 0">
								{{ countChicks }}
							</b-badge>
						</b-nav-item>
						<b-nav-item
							link-classes="px-1 justify-content-between w-100"
							:active="tab == 3"
							:disabled="countCintillos == 0"
							@click="tab = 3"
						>
							<span>CINTILLOS PATA</span>
							<b-badge pill variant="danger" class="ml-1" v-if="countCintillos > 0">
								{{ countCintillos }}
							</b-badge>
						</b-nav-item>
					</ul>
				</div>

				<div v-if="tab == 1" class="px-1 containers-genders">
					<!-- {{ specimens[0] }} -->
					<ContainerGenderCard
						:specimens="specimens.filter((it) => it.specimen.gender == 'MACHO')"
						title="MACHOS"
					/>

					<ContainerGenderCard
						:specimens="specimens.filter((it) => it.specimen.gender == 'HEMBRA')"
						title="HEMBRAS"
					/>
				</div>

				<b-row v-if="tab == 2" class="px-1">
					<b-col cols="12" lg="4" class="mb-1" sm="6" v-for="(group, i) in chicks" :key="i">
						<!-- {{ group.feature }} -->
						<div class="w-100" v-b-tooltip.hover :title="group.feature ? 'Corral actual' : ''">
							<b-card
								:class="group.feature ? 'border-warning rounded' : 'border-light rounded'"
								:header-bg-variant="group.feature ? 'light bg-warning font-weight-bold ' : 'light'"
								header-class="d-block font-weight-bolder p-1 text-center"
								:header="group.name"
								body-class="container-chicks-corrales"
							>
								<div
									v-b-tooltip.hover
									class="chick-container"
									v-for="(chick, i) in group.chicks"
									:key="i"
									:class="[
										chick.chick.gender_id == 1 ? 'bg-primary text-white' : '',
										chick.chick.gender_id == 2 ? 'bg-info' : '',
										chick.chick.gender_id == 3 ? 'bg-secondary text-white' : '',
									]"
								>
									{{ chick.chick.correlative }}
								</div>
							</b-card>
						</div>
					</b-col>
				</b-row>
				<b-row v-if="tab == 3" class="px-1">
					<b-col cols="12" lg="4" class="mb-1" sm="6" v-for="(lote, i) in lotes" :key="i">
						<b-card
							class="border-light rounded"
							header-bg-variant="light"
							header-class="d-block font-weight-bolder p-1 text-center"
							:header="lote.lote_code"
							body-class="container-chicks-corrales"
						>
							<b-table-simple>
								<b-tr class="text-center bg-white">
									<b-th>Cantidad</b-th>
									<b-th>Postura</b-th>
									<b-th>Colores</b-th>
								</b-tr>
								<b-tr class="text-center bg-white">
									<b-td>{{ lote.bal }}</b-td>
									<b-td>{{ lote.postura_code }}</b-td>
									<b-td>
										<CombChickenlegsCintillos
											:postura="{
												chickenleg_wich_leg: lote.cintillos.wich_leg,
												chickenleg_qty_cintillos: lote.cintillos.qty_cintillos,
												cintillo_color: lote.cintillos.hex_1,
												cintillo_description: lote.cintillos.description_1,
												second_color_color: lote.cintillos.hex_2,
												second_color_description: lote.cintillos.description_2,
												total_minus_infertile_eggs: 1,
											}"
											:chickenleg_wich_leg="1"
										/>
									</b-td>
								</b-tr>
							</b-table-simple>
						</b-card>
					</b-col>
				</b-row>
			</div>
		</b-overlay>
	</b-sidebar>
</template>

<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import CardSpecimen from "./CardSpecimen.vue"
import corralService from "@/services/encaste/corral.service"
import CombChickenlegsCintillos from "@/views/amg/encaste/lotes/components/CombChickenlegsCintillos.vue"
import TableGenderBrothersBySpecimen from "@/views/amg/specimens/dashboard/modals/TableGenderBrothersBySpecimen.vue"
import ContainerGenderCard from "@/views/amg/specimens/dashboard/modals/ContainerGenderCard.vue"

export default {
	props: {
		show: Boolean,
		parents: Object,
		corral_id: Number,
		corral_code: String,
		// { padrillo: int, madrilla: int}
	},
	components: {
		SpecimenPlate,
		CardSpecimen,
		CombChickenlegsCintillos,
		TableGenderBrothersBySpecimen,
		ContainerGenderCard,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		fields: ["Especimen", "Estado", "Postura"],
		rows: [],
		specimens: [],
		tab: 0,
		chicks: [],
		countChicks: 0,
		countAlas: 0,
		cParents: {
			madrilla: null,
			padrillo: null,
		},
		countCintillos: 0,
		lotes: [],
		countersGender: null,
	}),
	computed: {},
	methods: {
		handleHidden() {
			this.isActive = false
			this.$emit("closing")
		},
		async getRows() {
			this.isPreloading()
			this.isActive = false
			this.isLoading = true
			let { data } = await corralService.viewChildrensByParents({ ...this.parents, corral_id: this.corral_id })
			this.cParents.padrillo = data.parents[0]
			this.cParents.madrilla = data.parents[1]
			// this.cParents = {
			// 	padrillo: data.parents.filter((parent) => parent.gender == "MACHO"),
			// 	madrilla: data.parents.filter((parent) => parent.gender == "HEMBRA"),
			// };
			this.rows = [...data.children]
			if (this.rows.length < 1) {
				this.$emit("closing")
				this.isPreloading(false)
				this.isActive = false
				return this.showToast("warning", "top-right", "Hermanos", "InfoIcon", "No se encontraron registros")
			}
			this.isActive = true
			this.specimens = data.children.filter((sibling) => sibling.specimen != null)
			this.tab = this.specimens.length > 0 ? 1 : 2
			this.chicks = data.children.filter((sibling) => sibling.specimen == null)
			this.countChicks = [...this.chicks].length
			let lotesPatas = data.cintillos_pata.filter((lote) => lote.bal > 0) // se filtrara en back
			this.lotes = { ...lotesPatas }
			this.countCintillos = data.cintillos_pata.reduce((acc, val) => Number(val.bal) + acc, 0)
			let corrales = [null]
			// group cicks by corral
			this.chicks.forEach((chick) => {
				if (!corrales.includes(chick.chick.corral)) {
					corrales.push(chick.chick.corral)
				}
			})
			this.chicks = corrales.map((id) => {
				let chicks = []
				this.chicks.forEach((item) => {
					if (item.chick.corral == id) {
						chicks.push(item)
					}
				})

				// let alias = chicks[0].chick.corral_alias || id;
				let alias = null

				if (chicks.length > 0) alias = chicks[0].chick.corral_alias

				return {
					name: id == null ? "SIN CORRAL" : alias,
					chicks,
					feature: this.corral_code == id,
				}
			})
			// Limpiar grupos que no tengan chicks
			this.chicks = this.chicks.filter((group) => group.chicks.length != 0)
			this.isActive = true
			this.isLoading = false
			this.getCounter()
			this.isPreloading(false)
		},
		rowToObject(row) {
			if (typeof row.chick == "object") {
				return {
					plate: row.chick.correlative,
					id: row.chick.id,
				}
			}
			return {
				plate: row.specimen.plate,
				alias: row.specimen.alias,
				id: row.specimen.id,
				thumb: row.specimen.thumb,
				image: row.specimen.image,
			}
		},
		async getCounter() {
			const data = await corralService.getCounterChildrenAndGender({
				madrilla_id: this.parents.madrilla_id,
				padrillo_id: this.parents.padrillo_id,
				corral_id: this.corral_id,
			})
			this.countersGender = data
		},
	},
	created() {},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getRows()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.container-table-genders-children {
	width: 100%;
	@media screen and (min-width: 900px) {
		width: 80%;
		text-align: right;
	}
	@media screen and (min-width: 1400px) {
		width: 60%;
		text-align: right;
	}
}

.container-chicks-corrales {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 1rem !important;
	background: #fafafa;
}

.chick-container {
	border-radius: 12px;
	padding: 0.2rem 0.5rem;
	margin-right: 1rem;
	font-weight: bolder;
	margin-bottom: 1rem;
}
.containers-genders {
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	padding: 2rem;
	padding: 2rem;
	@media screen and (min-width: 1400px) {
		grid-template-columns: 1fr 1fr;
	}
}
</style>
