<template>
	<b-modal centered no-close-on-backdrop title="Cambio de corral masivo" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-form-group label="Seletor masivo" description="Cambiara todos los corrales a la vez">
				<v-select label="text" :options="corralOpts" :reduce="(opt) => opt.value" @input="assignToAll">
					<template v-slot:option="opt">
						<span>
							{{ opt.text }}
							<small>/ PA: {{ opt.chicks }}</small>
						</span>
					</template>
					<template v-slot:selected-option="opt">
						<span>
							{{ opt.text }}
							<small>/ PA: {{ opt.chicks }}</small>
						</span>
					</template>
				</v-select>
			</b-form-group>
			<ValidationObserver ref="form">
				<b-table-simple>
					<b-thead class="text-center">
						<b-tr>
							<b-th>Correlativo</b-th>
							<b-th>Corral</b-th>
						</b-tr>
					</b-thead>
					<b-tbody class="text-center">
						<b-tr v-for="chick in chicks" :key="chick.id">
							<b-td>{{ chick.correlative }}</b-td>
							<b-td>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<v-select
										label="text"
										:class="{ 'border-danger rounded': errors[0] }"
										:options="corralOpts"
										:reduce="(opt) => opt.value"
										v-model="chick.destiny"
									>
										<template v-slot:option="opt">
											<span>
												{{ opt.text }}
												<small>/ PA: {{ opt.chicks }}</small>
											</span>
										</template>
										<template v-slot:selected-option="opt">
											<span>
												{{ opt.text }}
												<small>/ PA: {{ opt.chicks }}</small>
											</span>
										</template>
									</v-select>
								</ValidationProvider>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
				<small class="text-dark">
					<feather-icon class="text-warning mr-05" icon="InfoIcon" />
					PA: Pollos actuales
				</small>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="success" @click="updateChicks">Enviar</b-button>
		</template>
	</b-modal>
</template>

<script>
import corralService from "@/services/encaste/corral.service"

export default {
	props: {
		show: Boolean,
		info: Object, // [chicks:Object[],corral:Object]
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		corralOpts: [],
		chicks: [],
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.chicks = []
			this.$emit("closing")
		},
		async getOpts() {
			this.isLoading = true
			const res = await corralService.index({ page: 1, perpage: 50, statusId: 1 })
			this.corralOpts = res.corrales.data
				.filter((c) => c.id != this.info.corral.id)
				.map((c) => ({
					value: c.id,
					text: c.alias,
					code: c.code,
					chicks: Number(c.total_chicks),
				}))
			this.isLoading = false
		},
		parseChicks() {
			this.chicks = this.info.chicks.map((c) => ({
				id: c.id,
				gender: c.gender,
				correlative: c.correlative,
				destiny: null,
			}))
		},
		assignToAll(e) {
			this.chicks.forEach((c) => (c.destiny = e))
		},
		async updateChicks() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se cambiara el corral`,
			})
			if (!isConfirmed) return
			this.isLoading = true
			const chicks = this.chicks.map((c) => ({ id: c.id, new_corral_id: c.destiny }))
			await corralService.changeCorralChicks({ chicks })
			this.isLoading = false
			this.$emit("refresh")
			this.handleHidden()
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getOpts()
				this.parseChicks()
			}
		},
	},
}
</script>
