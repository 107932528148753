<template>
	<div class="container-brothers-gender">
		<b-table-simple v-if="countersGender" class="table mb-0 bg-white">
			<b-thead>
				<b-tr class="text-center">
					<b-th class="p-0">
                        <div class="container-header-table">
                           <p class="mb-0"> #</p>
                        </div>
                    </b-th>
					<b-th class="p-0">
                        <div class="container-header-table">
                            <p class="mb-0 ">Machos</p>
                        </div>
                    </b-th>
					<b-th class="p-0">
                        <div class="container-header-table">
                            <p class="mb-0 ">Hembras</p>
                        </div>
                    </b-th>
					<b-th class="p-0">
                        <div class="container-header-table">
                            <p class="mb-0 ">Sin asignar</p>
                        </div>
                    </b-th>
					<b-th class="p-0" >
                        <div class="container-header-table">
                            <p class="mb-0">Total</p>
                        </div>
                    </b-th>
				</b-tr>
			</b-thead>
			<b-tbody>
				<b-tr class="text-center">
					<b-td class="p-50"> EJEMPLARES </b-td>
					<b-td>
						{{ countersGender.specimens.machos }}
					</b-td>
					<b-td class="p-50">
						{{ countersGender.specimens.hembras }}
					</b-td>
					<b-td class="p-50">
						{{ countersGender.specimens.sin_asignar }}
					</b-td>
					<b-td class="p-50" style="background: #F4F1F7;">
						{{
							countersGender.specimens.machos +
							countersGender.specimens.hembras +
							countersGender.specimens.sin_asignar
						}}
					</b-td>
				</b-tr>
				<b-tr class="text-center">
					<b-td class="p-50"> POLLOS </b-td>
					<b-td class="text-primary font-weight-bolder">
						{{ countersGender.chicks.machos }}
					</b-td>
					<b-td class="p-50 text-info font-weight-bolder">
						{{ countersGender.chicks.hembras }}
					</b-td>
					<b-td class="p-50 text-secondary font-weight-bolder">
						{{ countersGender.chicks.sin_asignar }}
					</b-td>
					<b-td class="p-50" style="background: #F4F1F7;">
						{{
							countersGender.chicks.machos +
							countersGender.chicks.hembras +
							countersGender.chicks.sin_asignar
						}}
					</b-td>
				</b-tr>
				<b-tr class="text-center">
					<b-td class="p-50"> CINTILLO PATA </b-td>
					<b-td>
						{{ '---' }}
					</b-td>
					<b-td class="p-50">
						{{ '---' }}
					</b-td>
					<b-td class="p-50">
						{{ countersGender.patas.sin_asignar }}
					</b-td>
					<b-td class="p-50" style="background: #F4F1F7;">
						{{
							countersGender.patas.machos +
							countersGender.patas.hembras +
							countersGender.patas.sin_asignar
						}}
					</b-td>
				</b-tr>
 
				<b-tr class="text-center" style="background: #F4F1F7;"> 
					<b-td class="p-50"> Total </b-td>
					<b-td>
						{{ totalMachos }}
					</b-td>
					<b-td class="p-50">
						{{ totalHembras }}
					</b-td>
					<b-td class="p-50">
						{{ totalSinasignar }}
					</b-td>
					<b-td class="p-50">
						{{ totalMachos + totalHembras + totalSinasignar }}
					</b-td>
				</b-tr>
			</b-tbody>
		</b-table-simple>
	</div>
</template>

<script>
export default {
	name: "TableGenderBrothersBySpecimen",
	props: {
		countersGender: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		totalMachos() {
			return (
				this.countersGender.specimens.machos +
				this.countersGender.chicks.machos +
				this.countersGender.patas.machos
			);
		},
		totalHembras() {
			return (
				this.countersGender.specimens.hembras +
				this.countersGender.chicks.hembras +
				this.countersGender.patas.hembras
			);
		},
		totalSinasignar() {
			return (
				this.countersGender.specimens.sin_asignar +
				this.countersGender.chicks.sin_asignar +
				this.countersGender.patas.sin_asignar
			);
		},
	},
};
</script>

<style lang="scss">
.container-brothers-gender{
    width: 100%;
    overflow: auto;
    .plate {
        min-width: 600px;
    }
}
.container-header-table{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    p{
        font-size: 10px;
        // background-color: red;
        padding: 0 .4rem;
        line-height: 1.1;
    }
}
</style>