<template>
	<div v-if="showLegs" class="d-flex flex-nowrap justify-content-around align-items-center" style="gap: 5px">
		<template v-if="postura.chickenleg_wich_leg === chickenleg_wich_leg">
			<template v-if="postura.chickenleg_qty_cintillos === 1 || postura.chickenleg_qty_cintillos === 2">
				<div
					:style="`background-color: ${postura.cintillo_color};`"
					class="cintillo"
					v-b-tooltip.hover
					:title="postura.cintillo_description"
				>
					<span v-if="is_in_posturas">
						{{ postura.chickenleg_wich_leg == 1 || postura.chickenleg_wich_leg == 3 ? "I" : "D" }}
					</span>
				</div>

				<div
					v-if="postura.second_color_color && postura.chickenleg_qty_cintillos !== 2"
					:style="`background-color: ${postura.second_color_color};`"
					class="cintillo"
					v-b-tooltip.hover
					:title="postura.second_description"
				>
					<span v-if="is_in_posturas">
						{{ postura.chickenleg_wich_leg == 1 || postura.chickenleg_wich_leg == 3 ? "I" : "D" }}
					</span>
				</div>

				<div
					v-if="postura.chickenleg_qty_cintillos === 2 && postura.chickenleg_wich_leg !== 3"
					:style="`background-color: ${
						postura.second_color_color ? postura.second_color_color : postura.cintillo_color
					};`"
					class="cintillo"
					v-b-tooltip.hover
					:title="
						postura.second_color_description
							? postura.second_color_description
							: postura.cintillo_description
					"
				>
					<span v-if="is_in_posturas">
						{{ postura.chickenleg_wich_leg == 1 || postura.chickenleg_wich_leg == 3 ? "I" : "D" }}
					</span>
				</div>
			</template>
		</template>
		<template v-if="postura.chickenleg_wich_leg === 3">
			<!-- DOS COLORES -->
			<div
				v-if="chickenleg_wich_leg == 1"
				:style="`background-color: ${postura.cintillo_color};`"
				class="cintillo"
				v-b-tooltip.hover
				:title="postura.cintillo_description"
			>
				<span v-if="is_in_posturas">
					{{ chickenleg_wich_leg === 1 ? "I" : "D" }}
				</span>
			</div>
			<div
				v-if="chickenleg_wich_leg == 2"
				:style="`background-color: ${
					postura.second_color_color ? postura.second_color_color : postura.cintillo_color
				};`"
				class="cintillo"
				v-b-tooltip.hover
				:title="
					postura.second_color_description ? postura.second_color_description : postura.cintillo_description
				"
			>
				<span v-if="is_in_posturas">
					{{ chickenleg_wich_leg === 2 ? "D" : "I" }}
				</span>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	name: "CombChickenlegsCintillos",
	props: {
		postura: {
			type: Object,
			required: true,
		},
		chickenleg_wich_leg: {
			type: Number,
			required: true,
		},
		is_in_posturas: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		showLegs() {
			if (this.is_in_posturas) {
				return true;
			}
			if (this.postura && this.postura.total_minus_infertile_eggs > 0) {
				return true;
			}
			return false;
		},
	},
};
</script>

<style lang="scss">
.cintillo {
	width: 25px;
	height: 25px;
	clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 64%, 0 100%);
	position: relative;
	margin-right: 0.5rem;

	:last-child {
		margin-right: 0;
	}

	span {
		font-weight: bold;
		position: absolute;
		left: 50%;
		translate: -50%;
		color: #fff;
		font-size: 12px;
	}
}

.border-black {
	border: 1px solid #252558;
}
</style>
