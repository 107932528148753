<template>
	<b-modal
		centered
		:title="`${info.category_id == 1 ? 'Padrillos del corral' : 'Madrillas del corral'} ${info.alias || info.code}`"
		v-model="isActive"
		@hidden="handleHidden"
		hide-footer
		size="md"
	>
		<b-overlay :show="isLoading">
			<!-- {{ specimens }} -->
			<b-table-simple sticky-header class="table mb-0" :style="{ maxHeight: '70vh' }">
				<b-thead>
					<b-tr class="text-center">
						<b-th class="p-50">#</b-th>
						<b-th class="p-50">{{ info.category_id == 1 ? "Padrillos" : "Madrillas " }}</b-th>
						<b-th class="p-50">Hijos</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr class="text-center" v-for="(item, index) in specimens" :key="index">
						<b-td class="p-50">
							{{ index + 1 }}
						</b-td>
						<b-td>
							<SpecimenPlate
								:specimen="{
									id: item.specimen.id,
									plate: item.specimen.plate,
									alias: item.specimen.alias,
									thumb: item.specimen.thumb,
									image: item.specimen.image,
								}"
							/>
						</b-td>
						<b-td class="p-50">
							{{ item.childs }}
						</b-td>
					</b-tr>
					<b-tr class="text-center font-weight-bold" v-if="specimens.length == 0">
						<b-td colspan="4">No hay datos para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>

			<div class="container-specimens">
				<div class=""></div>
			</div>
		</b-overlay>
	</b-modal>
</template>

<script>
import corralService from "@/services/encaste/corral.service"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"

export default {
	components: { SpecimenPlate },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		specimens: [],
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		async init() {
			this.isLoading = true
			const { data } = await corralService.parentsInvolvedInThePlaypen({
				category_id: this.info.category_id,
				corral_id: this.info.id,
			})
			this.specimens = data
			this.isLoading = false
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.init()
			}
		},
	},
}
</script>

<style scoped lang="scss">
.container-specimens {
	// display: grid;
	// grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	// gap: 1rem;
}
</style>
